import React from 'react'
import SEO from "../components/atoms/seo"
import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import Questionnaire from '../components/organisms/questionnaire'
import KeyFeatures from '../components/molecules/key-features'

import steps from "../data/pages/questionnaire/steps.json"
import verifyInformation from "../data/pages/questionnaire/verify.json"
import content from "../data/pages/questionnaire/content.json"

const QuestionnairePage = () => (
  <Layout type='bath' showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    {/* <Heading 
        title='No Payments, No Interest For 18 Months'
        subTitle=''
        weight='regular'
    /> */}
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      // formType="bath"
      thankYouChatBot
    />
    <KeyFeatures
      type="bath"
      features={[
        {
            title: '45-day<br/>risk-free<br/>trial',
            image: 'risk-free-trial.png',
            imageAlt: ''
        },
        {
            title: 'Hearing care at<br/>the comfort of&nbsp;your own&nbsp;home',
            image: 'home-heart.png',
            imageAlt: ''
        },
        {
          title: '100%<br/>money-back<br/>guarantee',
          image: 'money-back.png',
          imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
